import React from 'react'
import { Link, PageProps } from 'gatsby'
import { Head, WelcomeHero, Button, Footer } from '~/components'
import { navItems } from '~/data'
import Banner from '~/assets/img/Hero-Atendimento.jpg'
import * as st from '~/assets/styl/Attendance.module.styl'

const items = navItems.find((i) => i.name === 'Atendimento').subItems

type CardProps = {
  name: string
  icon?: string
  children: React.ReactNode
}

const Card = ({ name, icon = 'arrow', children }: CardProps) => {
  const { url, page = true } = items.find((i) => i.name === name)

  return (
    <li>
      {page === 'external' || !page ? (
        <a href={url} target="_blank" rel="noreferrer">
          <h4>{name}</h4>
          <p>{children}</p>
          <Button
            type="outlined small icon"
            to={url}
            external={page === 'external' || !page}
            className={st.btn}
          >
            <span className={`icon-${icon}`}></span>
          </Button>
        </a>
      ) : (
        <Link to={url}>
          <h4>{name}</h4>
          <p>{children}</p>
          <Button
            type="outlined small icon"
            to={url}
            external={page === 'external' || !page}
            className={st.btn}
          >
            <span className={`icon-${icon}`}></span>
          </Button>
        </Link>
      )}
    </li>
  )
}

const Attendance = ({ location }: PageProps) => (
  <>
    <Head location={location} title="Atendimento Alínea" />

    <WelcomeHero title="Atendimento Alínea" banner={Banner} />

    <section className={st.core}>
      <div className="container">
        <h3>
          Precisando de algo?
          <br />
          Entre em contato com a gente.
        </h3>
        <p>
          Aqui você pode tirar suas dúvidas, solicitar informações sobre os
          empreendimentos Alínea, oferecer seus serviços e muito mais.
          <br />
          Utilize os canais abaixo para entrar em contato com nossa equipe.
        </p>
        <ul>
          <Card name="Fale conosco">
            Precisando de informações sobre nossos loteamentos?
          </Card>
          <Card name="Ouvidoria" icon="phone">
            <strong>(48) 3381-7000</strong>
            <br />
            Segunda a sexta das 8h as 18h
          </Card>
          <Card name="Fale com o presidente">
            Seu contato não foi solucionado?
            <br />
            Utilize este canal exclusivo.
          </Card>
          <Card name="Avalie seu atendimento">
            Avalie a qualidade do atendimento prestado pelo seu corretor.
          </Card>
          <Card name="Trabalhe conosco">
            Conheça as oportunidades para trabalhar na Alínea.
          </Card>
          <Card name="Ofereça sua área">
            Você possui uma área acima de 5 hectares?
            <br />
            Faça contato conosco.
          </Card>
          <Card name="Seja um parceiro">
            Ofereça seus produtos ou serviços e seja um fornecedor da Alínea.
          </Card>
          <Card name="Seja um corretor">
            Faça parte do nosso time e trabalhe com profissionais
            especializados.
          </Card>
        </ul>
      </div>
    </section>

    <Footer />
  </>
)

export default Attendance
